import { TrackedLink } from '@flock/shared-ui'
import { Info, OpenInNew } from '@mui/icons-material'
import { Box, styled, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { formatDollarsAndCentsInvestor } from '../../../../utils/formatting'
import { TaxSummaryTableProps } from './TaxSummaryTableTypes'

const StyledOpenInNewOutline = styled(OpenInNew)({
  width: '20px',
  height: '20px',
})

const StyledInfoOutline = styled(Info)({
  width: '16px',
  height: '16px',
})

const StyledInfoTooltip = styled((props: any) => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))`
  & .MuiTooltip-tooltip {
    background-color: #e9eaeb;
    border-radius: 8px;
    padding: 8px 12px 8px 12px;
  }

  & .MuiTooltip-arrow::before {
    color: #e9eaeb;
  }
`

const TaxManualLink =
  'https://flock-static-documents.s3.us-east-2.amazonaws.com/production/Tax+Manual.pdf'

const TaxSummaryTableDesktop = (props: TaxSummaryTableProps) => {
  const { taxData } = props
  const {
    currentCostBasisCents,
    totalDepreciationCents,
    totalTaxableIncomeCents,
    totalEmbeddedGainCents,
    totalExpectedTaxableIncomeCents,
  } = taxData

  const currentCostBasisString = formatDollarsAndCentsInvestor(
    Math.round(currentCostBasisCents / 100)
  )
  const totalDepreciationString = formatDollarsAndCentsInvestor(
    Math.round(totalDepreciationCents / 100)
  )
  const totalTaxableIncomeString = formatDollarsAndCentsInvestor(
    Math.round(totalTaxableIncomeCents / 100)
  )
  const totalEmbeddedGainString = formatDollarsAndCentsInvestor(
    Math.round(totalEmbeddedGainCents / 100)
  )
  const totalExpectedTaxableIncomeString = formatDollarsAndCentsInvestor(
    Math.round(totalExpectedTaxableIncomeCents / 100)
  )

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      borderRadius="8px"
      border="2px solid"
      borderColor="green0.main"
      p="16px"
      gap="8px"
      width="288px"
      id="tax"
    >
      <Typography variant="cta2" color="moneyGreen.main">
        Tax Information
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        gap="4px"
        alignSelf="stretch"
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="baseline"
          alignSelf="stretch"
        >
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography variant="p3" color="moneyGreen.main">
              Total taxable income &nbsp;
            </Typography>
            <StyledInfoTooltip
              title={
                <Typography variant="p3" marginRight="6px" color="gray8.main">
                  Here we show cumulative taxable income allocated to you via
                  Flock Schedule K-1s. Depreciation and other deductions
                  you&apos;ve received are already included here.
                </Typography>
              }
              enterTouchDelay={0}
              data-cy="initialContributionsTooltip"
            >
              <StyledInfoOutline fontSize="small" color="disabled" />
            </StyledInfoTooltip>
          </Box>

          <Typography variant="p2" color="moneyGreen.main">
            {totalTaxableIncomeString}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="baseline"
          alignSelf="stretch"
          pl="16px"
        >
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography variant="p3" color="moneyGreen.main">
              Expected (2024) &nbsp;
            </Typography>
            <StyledInfoTooltip
              title={
                <Typography variant="p3" marginRight="6px" color="gray8.main">
                  Estimate as of Q3 &apos;24 and assumes certain portfolio
                  activities that may change by year-end. Figures will be
                  updated periodically. Do not use this number for official tax
                  reporting; for indicative tax planning purposes only. If you
                  joined Flock after September 1, 2024, this figure will be
                  updated in the coming quarter.
                </Typography>
              }
              enterTouchDelay={0}
              data-cy="initialContributionsTooltip"
            >
              <StyledInfoOutline fontSize="small" color="disabled" />
            </StyledInfoTooltip>
          </Box>
          <Typography variant="p2" color="moneyGreen.main">
            {totalExpectedTaxableIncomeCents
              ? totalExpectedTaxableIncomeString
              : 'Coming Soon'}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="baseline"
          alignSelf="stretch"
        >
          <Typography variant="p3" color="moneyGreen.main">
            Current cost basis
          </Typography>
          <Typography variant="p2" color="moneyGreen.main">
            {currentCostBasisString}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="baseline"
          alignSelf="stretch"
        >
          <Typography variant="p3" color="moneyGreen.main">
            Depreciation claimed
          </Typography>
          <Typography variant="p2" color="moneyGreen.main">
            {totalDepreciationString}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="baseline"
          alignSelf="stretch"
        >
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography variant="p3" color="moneyGreen.main">
              Embedded gain &nbsp;
            </Typography>
            <StyledInfoTooltip
              title={
                <Typography variant="p3" marginRight="6px" color="gray8.main">
                  This number corresponds to Section N of your Federal Schedule
                  K-1. It represents the sum of (1) the built-in gain at
                  contribution of property and (2) your share of the broader
                  appreciation of Flock&apos;s portfolio since initially
                  investing. If you were to have redeemed from Flock at the end
                  of the tax year, this is approximately how much gain
                  you&apos;d recognize.
                </Typography>
              }
              enterTouchDelay={0}
              data-cy="initialContributionsTooltip"
            >
              <StyledInfoOutline fontSize="small" color="disabled" />
            </StyledInfoTooltip>
          </Box>
          <Typography variant="p2" color="moneyGreen.main">
            {totalEmbeddedGainString}
          </Typography>
        </Box>
      </Box>
      <TrackedLink
        variant="p2"
        color="green4.main"
        lineHeight="24px"
        onClick={() => {
          window.open(TaxManualLink, '_blank')
        }}
        alignItems="center"
      >
        View the tax manual <StyledOpenInNewOutline />
      </TrackedLink>
    </Box>
  )
}

export default TaxSummaryTableDesktop
