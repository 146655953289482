import { RightArrowIcon, TrackedLink } from '@flock/shared-ui'
import { Box, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import { useSnackbar } from '@flock/flock-component-library'
import { Download, OpenInNew } from '@mui/icons-material'
import { useQuery } from '@apollo/client'
import { InvestorGetDocumentDownloadUrlDocument } from '@flock/flock-gql-server/src/__generated__/graphql'

import { useInvestorAccountContext } from '../InvestorAccountContext'

type DocumentCardProps = {
  documentImage: string
  documentTitle: string
  documentUuid?: string
  documentLink?: string
  sourceUuid?: string
}

const DocumentCard = (props: DocumentCardProps) => {
  const {
    documentImage,
    documentTitle,
    documentUuid,
    documentLink,
    sourceUuid,
  } = props
  const { notify } = useSnackbar()
  const { selectedLegalEntity } = useInvestorAccountContext()
  const { refetch } = useQuery(InvestorGetDocumentDownloadUrlDocument, {
    skip: true,
  })

  const downloadDocument = async () => {
    try {
      if (documentLink !== '') {
        window.open(documentLink, '_blank')
      } else {
        const urlData = await refetch({
          input: {
            legalEntityUuid: sourceUuid || selectedLegalEntity?.uuid,
            sourceUuid: sourceUuid || selectedLegalEntity?.uuid,
            documentUuid: documentUuid as string,
          },
        })
        window.open(
          urlData?.data?.legalEntityDocumentPresignedUrl
            ?.presignedUrl as string,
          '_blank'
        )
      }
    } catch (e) {
      notify(
        'An error while downloading the file. Please refresh or try again.',
        'error'
      )
    }
  }
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  return isTablet ? (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      pt="4px"
      onClick={downloadDocument}
    >
      <TrackedLink
        variant="p3"
        sx={{ color: '#136D42!important' }}
        onClick={downloadDocument}
      >
        {documentTitle}
      </TrackedLink>
      <RightArrowIcon
        onClick={downloadDocument}
        width="28px"
        height="28px"
        color="green4"
      />
    </Box>
  ) : (
    <Box display="flex" flexDirection="column">
      <Box
        component="img"
        height="300px"
        width="218px"
        src={documentImage}
        pt="16px"
        sx={{ '&:hover': { cursor: 'pointer' } }}
        onClick={downloadDocument}
      />
      <TrackedLink
        sx={{ paddingTop: '8px' }}
        variant="p2"
        onClick={downloadDocument}
      >
        {documentTitle}
      </TrackedLink>
      {documentLink ? (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          pt="4px"
        >
          <TrackedLink
            variant="p3"
            sx={{ color: '#136D42!important' }}
            onClick={downloadDocument}
          >
            Open in new tab
          </TrackedLink>
          <OpenInNew
            onClick={downloadDocument}
            sx={{
              height: '16px',
              width: '16px',
              '&:hover': { cursor: 'pointer' },
            }}
          />
        </Box>
      ) : (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          pt="4px"
        >
          <TrackedLink
            variant="p3"
            sx={{ color: '#136D42!important' }}
            onClick={downloadDocument}
          >
            Download document
          </TrackedLink>
          <Download
            onClick={downloadDocument}
            sx={{
              height: '16px',
              width: '16px',
              '&:hover': { cursor: 'pointer' },
            }}
          />
        </Box>
      )}
    </Box>
  )
}

export default DocumentCard

DocumentCard.defaultProps = {
  documentUuid: '',
  documentLink: '',
  sourceUuid: undefined,
}
