// eslint-disable-next-line import/prefer-default-export
export const FLOCK_LANDING_URL = process.env.GATSBY_FLOCK_LANDING_URL
export const FLOCK_INVESTOR_URL = process.env.GATSBY_FLOCK_INVESTOR_URL
export const MAPBOX_TOKEN = process.env.GATSBY_MAPBOX_TOKEN || ''
export const GOOGLE_MAPS_API_KEY = process.env.GATSBY_GOOGLE_MAPS_API_KEY
export const ENABLE_SIMULATOR = process.env.GATSBY_ENABLE_SIMULATOR === 'true'
export const ENABLE_MAINTENANCE_SCREEN =
  process.env.GATSBY_ENABLE_MAINTENANCE_SCREEN === 'true'
export const { GATSBY_ENV } = process.env
export const { GATSBY_PARALLEL_MARKETS_CLIENT_ID } = process.env

export const DENVER_ADDRESS = '2930 Umatilla St, Denver, CO 80211'
export const EXCHANGE_SERVICING_SLACK_CHANNEL = 'C02KYD1V5FU'
export const DANIELLE_SLACK_ID = '<@U0342MF75CN>'
export const JACOB_SLACK_ID = '<@U05609FD8US>'
export const ACCOUNT_URL = '/app/account'
export const ORDERS_URL = '/app/orders'
export const DOCUMENTS_URL = '/app/documents'
export const CALLBACK_PATH = '/callback'
export const HOLDINGS_URL = '/app/holdings'
export const SIMULATOR_URL = '/app/simulator'
export const PORTFOLIO_URL = '/app/fund-holdings'
export const PROPERTY_QUESTIONNAIRE_URL = '/app/property-questionnaire'
export const LP_ONBOARDING_URL = '/app/lp-onboarding'
export const PERSONAL_INFORMATION_URL = '/app/personal-information'
export const ENTITY_INFORMATION_URL = '/app/entity-information'
export const TAX_INFORMATION_URL = '/app/tax-information'
export const FINANCIAL_INFORMATION_URL = '/app/financial-information'
export const FFO_URL = '/app/funds-from-operations'
export const BALANCE_SHEET_URL = '/app/balance-sheet'
export const DETAILED_ASSET_URL = '/app/assets'
export const INVESTMENT_PROJECTION_URL = '/app/investment-projection'
export const SHARE_PROPERTY_QUESTIONNAIRE_URL = '/shared-property-questionnaire'
export const SHARE_PROPERTY_QUESTIONNAIRE_V3_URL =
  '/shared-property-questionnaire-v3'
export const SHARE_PERSONAL_INFO_V3_URL = '/shared-personal-info-v3'
export const SHARE_MORTGAGE_V3_URL = '/shared-mortgage-v3'
export const ACCREDITATION_VERIFICATION_URL =
  'https://flock-public-docs.s3.us-east-2.amazonaws.com/Accredited+Investor+Verification+Letter.pdf'

export const SUBSCRIPTION_BOOKLET_URL =
  'https://flock-public-docs.s3.us-east-2.amazonaws.com/Flock+Subscription+Booklet.pdf'

export const FORM_4562_EXAMPLE_URL =
  'https://flock-public-docs.s3.us-east-2.amazonaws.com/Form+4562+Example.pdf'

export const DEPRECIATION_SCHEDULE_EXAMPLE_URL =
  'https://flock-public-docs.s3.us-east-2.amazonaws.com/Depreciation+Schedule+Example.pdf'

export const TURBOTAX_INSTRUCTIONS_URL =
  'https://flockhomes.docsend.com/view/h865tahyy9uxwwd7'

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const allStatesLongForm = [
  'Alabama',
  'Alaska',
  'American Samoa',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'District of Columbia',
  'Federated States of Micronesia',
  'Florida',
  'Georgia',
  'Guam',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Marshall Islands',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Northern Mariana Islands',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Palau',
  'Pennsylvania',
  'Puerto Rico',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virgin Island',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
]
