import { TrackedAccordion, TrackedLink } from '@flock/shared-ui'
import {
  Box,
  Grid,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { Download } from '@mui/icons-material'
import {
  Core_LegalEntityDocument as GqlLegalEntityDocument,
  InvestorDashboardGetAllDocumentsForInvestorDocument,
  InvestorGetDocumentDownloadUrlDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import React, { useCallback, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { useSnackbar } from '@flock/flock-component-library'
import { flatten } from 'lodash'
import { snakeToTitleCase } from '../utils'

import SectionLayout from '../SharedComponents/SectionLayout'
import PrivatePlacementMemorandumImage from '../../images/ppl-cover.webp'
import LimitedPartnershipAgreementImage from '../../images/lpa-cover.webp'
import RedlinedLPAImage101523 from '../../images/redline-cover.png'
import RedlinedLPAImage03 from '../../images/redline-cover-03.png'
import ContributionAgreementImage from '../../images/ca-cover.svg'
import SubscriptionBookletImage from '../../images/sb-cover.svg'
import GenericK1Image from '../../images/generic_k1.svg'
import FactSheetQ32024 from '../../images/q3-2024-fact-sheet.png'
import FactSheetQ22024 from '../../images/q2-2024-fact-sheet.png'
import FactSheetQ12024 from '../../images/q1-2024-fact-sheet.png'
import FactSheetQ42023 from '../../images/q4-2023-fact-sheet.png'
import FactSheetQ32023 from '../../images/q3-2023-fact-sheet.png'
import FactSheetQ22023 from '../../images/q2-2023-fact-sheet.png'
import FactSheetQ12023 from '../../images/q1-2023-fact-sheet.png'
import DocumentCard from './DocumentCard'
import { useInvestorAccountContext } from '../InvestorAccountContext'

type DocumentsSectionProps = {
  id: string
}

const DocumentsSection = (props: DocumentsSectionProps) => {
  const { id } = props
  const { legalEntities, investorAccountUuidOverride } =
    useInvestorAccountContext()
  const [documents, setDocuments] = useState<GqlLegalEntityDocument[][]>([])
  const { refetch } = useQuery(InvestorGetDocumentDownloadUrlDocument, {
    skip: true,
  })
  const { notify } = useSnackbar()

  const { refetch: getInvestorDocuments } = useQuery(
    InvestorDashboardGetAllDocumentsForInvestorDocument,
    {
      skip: true,
    }
  )

  const fetchAllDocuments = useCallback(async () => {
    const documentsQueries: any[] = []
    legalEntities.forEach((legalEntity) => {
      documentsQueries.push(
        getInvestorDocuments({
          input: {
            sourceType:
              legalEntity.uuid === 'aggregate'
                ? 'investor_account'
                : 'legal_entity',
            sourceUuid:
              legalEntity.uuid === 'aggregate'
                ? investorAccountUuidOverride
                : legalEntity.uuid,
          },
        })
      )
      if (legalEntities.length === 1) {
        documentsQueries.push(
          getInvestorDocuments({
            input: {
              sourceType: 'investor_account',
              sourceUuid: investorAccountUuidOverride,
            },
          })
        )
      }
    })

    const docs = await Promise.all(documentsQueries)
    setDocuments(
      docs.map((thing) => thing.data.getDocuments.legalEntityDocuments)
    )
  }, [getInvestorDocuments, investorAccountUuidOverride, legalEntities])

  useEffect(() => {
    fetchAllDocuments()
  }, [fetchAllDocuments])

  const downloadDocument = async (documentUuid: string, sourceUuid: string) => {
    try {
      const urlData = await refetch({
        input: {
          sourceUuid,
          documentUuid,
          legalEntityUuid: sourceUuid,
        },
      })
      window.open(
        urlData?.data?.legalEntityDocumentPresignedUrl?.presignedUrl as string,
        '_blank'
      )
    } catch (e) {
      notify(
        'An error while downloading the file. Please refresh or try again.',
        'error'
      )
    }
  }

  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))

  const FactSheetQ12023Link =
    'https://flock-static-documents.s3.us-east-2.amazonaws.com/production/Flock Quarterly Fact Sheet (1Q23)'
  const FactSheetQ22023Link =
    'https://flock-static-documents.s3.us-east-2.amazonaws.com/production/Flock Quarterly Fact Sheet (2Q23)'
  const FactSheetQ32023Link =
    'https://flock-static-documents.s3.us-east-2.amazonaws.com/production/Flock Quarterly Fact Sheet (3Q23)'
  const FactSheetQ42023Link =
    'https://flock-static-documents.s3.us-east-2.amazonaws.com/production/Flock Quarterly Fact Sheet (4Q23)'
  const FactSheetQ12024Link =
    'https://flock-static-documents.s3.us-east-2.amazonaws.com/production/Flock Quarterly Fact Sheet (1Q24)'
  const FactSheetQ22024Link =
    'https://flock-static-documents.s3.us-east-2.amazonaws.com/production/Flock Quarterly Fact Sheet (2Q24)'
  const FactSheetQ32024Link =
    'https://flock-static-documents.s3.us-east-2.amazonaws.com/production/Flock Quarterly Fact Sheet (3Q24)'

  const PrivatePlacementMemorandum2025Link =
    'https://flock-static-documents.s3.us-east-2.amazonaws.com/production/Flock+Homes+-+Private+Placement+Memorandum+(2025)'
  const LimitedPartnershipAgreement2025Link =
    'https://flock-static-documents.s3.us-east-2.amazonaws.com/production/Flock+Homes+-+Amended+and+Restated+Agreement+of+Limited+Partnership+(2025)'
  const CumRedline31124Link =
    'https://flock-static-documents.s3.us-east-2.amazonaws.com/production/(Cumulative+Redline+vs.+03-11-24+Version)+-+Flock+Homes+-+Amended+and+Restated+Agreement+of+Limited+Partnership+(Execution+Version).pdf'
  const LimitedPartnershipAgreement2024Link =
    'https://flock-static-documents.s3.us-east-2.amazonaws.com/production/Amended+and+Restated+Agreement+of+Limited+Partnership+(2024).pdf'
  const CumRedline101523Link =
    'https://flock-static-documents.s3.us-east-2.amazonaws.com/production/(Cumulative+Redline+vs.+10-15-23+Version)+-+Flock+Homes+-+Amended+and+Restated+Agreement+of+Limited+Partnership+(Execution+Version).pdf'

  const [collapseInvestment, setCollapseInvestment] = useState(true)
  const [collapseFund, setCollapseFund] = useState(true)
  const [collapseTax, setCollapseTax] = useState(true)
  const [collapseOtherDocs, setCollapseOtherDocs] = useState(true)

  const renderInvestmentDocumentsForInvestorAndLegalEntities = () =>
    legalEntities.map((legalEntity, idx) => {
      if (legalEntity.uuid === 'aggregate') {
        return undefined
      }
      const docs = documents[idx]
      const signedContributionAgreements = docs.filter(
        (doc: GqlLegalEntityDocument) =>
          doc.documentType === 'contribution_agreement_signed'
      )
      const signedSubscriptionDocs = docs.filter(
        (doc: GqlLegalEntityDocument) =>
          doc.documentType === 'subscription_booklet_signed'
      )

      if (
        signedContributionAgreements.length + signedSubscriptionDocs.length ===
        0
      ) {
        return undefined
      }

      return (
        <React.Fragment key={legalEntity.uuid}>
          <Grid item xs={12}>
            <Typography>{legalEntity.name}</Typography>
          </Grid>
          {signedContributionAgreements?.map(
            (signedContributionAgreement: GqlLegalEntityDocument) => (
              <Grid
                item
                xs={isTablet ? 12 : 4}
                key={signedContributionAgreement.uuid}
              >
                <DocumentCard
                  documentImage={ContributionAgreementImage}
                  documentTitle="Signed Contribution Agreement"
                  documentUuid={signedContributionAgreement.uuid || ''}
                  sourceUuid={legalEntity.uuid}
                />
              </Grid>
            )
          )}
          {signedSubscriptionDocs?.map(
            (signedSubscriptionDoc: GqlLegalEntityDocument) => (
              <Grid
                item
                xs={isTablet ? 12 : 4}
                key={signedSubscriptionDoc.uuid}
              >
                <DocumentCard
                  documentImage={SubscriptionBookletImage}
                  documentTitle="Signed Subscription Booklet"
                  documentUuid={signedSubscriptionDoc.uuid || ''}
                  sourceUuid={legalEntity.uuid}
                />
              </Grid>
            )
          )}
        </React.Fragment>
      )
    })

  const renderTaxDocumentsForLegalEntities = () => {
    const hasTaxDocuments = flatten(documents).some(
      (doc: GqlLegalEntityDocument) => doc.documentType === 'k1'
    )
    if (hasTaxDocuments) {
      return (
        <TrackedAccordion
          sx={{ px: isTablet ? '16px' : undefined }}
          summary="Tax"
          extended={!collapseTax}
          summaryOnClick={() => setCollapseTax(!collapseTax)}
        >
          <Grid container spacing={1}>
            {legalEntities.map((legalEntity, idx) => {
              const docs = documents[idx]
              const k1Docs = docs
                .filter(
                  (doc: GqlLegalEntityDocument) => doc.documentType === 'k1'
                )
                .sort(
                  (
                    docA: GqlLegalEntityDocument,
                    docB: GqlLegalEntityDocument
                  ) => docA.dateAdded - docB.dateAdded
                )
              if (k1Docs.length !== 0) {
                return (
                  <React.Fragment key={legalEntity.uuid}>
                    <Grid item xs={12} spacing={4}>
                      <Box pt="32px">
                        <Typography>{legalEntity.name}</Typography>
                      </Box>
                    </Grid>
                    {k1Docs?.map((k1Doc: GqlLegalEntityDocument) => (
                      <Grid item xs={isTablet ? 12 : 4} key={k1Doc.uuid}>
                        <DocumentCard
                          documentImage={GenericK1Image}
                          documentTitle={k1Doc.fileName || ''}
                          documentUuid={k1Doc.uuid || ''}
                          sourceUuid={
                            legalEntity.uuid === 'aggregate'
                              ? investorAccountUuidOverride
                              : legalEntity.uuid
                          }
                        />
                      </Grid>
                    ))}
                  </React.Fragment>
                )
              }
              return undefined
            })}
          </Grid>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            pt="32px"
            onClick={() => setCollapseTax(!collapseTax)}
          >
            <TrackedLink sx={{ color: '#136D42!important' }}>
              - Collapse Tax Documents
            </TrackedLink>
          </Box>
        </TrackedAccordion>
      )
    }
    return undefined
  }

  const renderOtherDocuments = () => {
    const otherDocTypes = [
      'k1',
      'quarter_fund_performance',
      'contribution_agreement_signed',
      'contribution_agreement',
      'subscription_booklet_signed',
      'subscription_booklet',
      'title_authorization',
      'settlement_statement',
    ]
    const hasOtherDocuments = flatten(documents).some(
      (doc: GqlLegalEntityDocument) =>
        !otherDocTypes.includes(doc.documentType || '')
    )
    if (hasOtherDocuments) {
      if (isTablet) {
        return (
          <TrackedAccordion
            summary="Other"
            extended={!collapseOtherDocs}
            summaryOnClick={() => setCollapseOtherDocs(!collapseOtherDocs)}
            sx={{ px: '16px' }}
          >
            {legalEntities.map((_, idx) => {
              const docs = documents[idx]
              const otherDocs = docs.filter(
                (doc: GqlLegalEntityDocument) =>
                  !otherDocTypes.includes(doc.documentType || '')
              )
              if (legalEntities.length === 1) {
                const investorDocs = documents[idx + 1]
                const investorOtherDocs = investorDocs.filter(
                  (investorDoc: GqlLegalEntityDocument) =>
                    !otherDocTypes.includes(investorDoc.documentType || '')
                )
                otherDocs.push(...investorOtherDocs)
              }

              if (otherDocs.length !== 0) {
                return otherDocs?.map((otherDoc: GqlLegalEntityDocument) => (
                  <Grid item xs={12} key={otherDoc.uuid}>
                    <DocumentCard
                      documentImage={FactSheetQ12023} // doc image doesnt matter on mobile / tablet
                      documentTitle={
                        otherDoc.fileName?.includes('Title')
                          ? 'Title Authorization'
                          : otherDoc.fileName || ''
                      }
                      documentUuid={otherDoc.uuid || ''}
                      sourceUuid={otherDoc.sourceUuid || ''}
                    />
                  </Grid>
                ))
              }

              return undefined
            })}
          </TrackedAccordion>
        )
      }
      return (
        <TrackedAccordion
          summary="Other"
          extended={!collapseOtherDocs}
          summaryOnClick={() => setCollapseOtherDocs(!collapseOtherDocs)}
        >
          <Grid container spacing={8} width="100%" ml="0px">
            <Box ml="0px">
              <Table style={{ marginTop: '50px' }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Date Uploaded</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                {legalEntities.map((legalEntity, idx) => {
                  const docs = documents[idx]
                  const otherDocs = docs.filter(
                    (doc: GqlLegalEntityDocument) =>
                      !otherDocTypes.includes(doc.documentType || '')
                  )
                  if (legalEntities.length === 1) {
                    const investorDocs = documents[idx + 1]
                    const investorOtherDocs = investorDocs.filter(
                      (investorDoc: GqlLegalEntityDocument) =>
                        !otherDocTypes.includes(investorDoc.documentType || '')
                    )
                    otherDocs.push(...investorOtherDocs)
                  }

                  if (otherDocs.length !== 0) {
                    return (
                      <React.Fragment key={legalEntity.uuid}>
                        {otherDocs?.map((otherDoc: GqlLegalEntityDocument) => {
                          const formattedDate = new Date(
                            otherDoc.dateAdded
                          ).toLocaleDateString('en-US', {
                            timeZone: 'UTC',
                          })
                          return (
                            <TableRow key={otherDoc.uuid}>
                              <TableCell>
                                {otherDoc.fileName?.includes('Title')
                                  ? 'Title Authorization'
                                  : otherDoc.fileName || ''}
                              </TableCell>
                              <TableCell>
                                {snakeToTitleCase(otherDoc.documentType || '')}
                              </TableCell>
                              <TableCell>{formattedDate}</TableCell>
                              <TableCell>
                                <Download
                                  onClick={() =>
                                    downloadDocument(
                                      otherDoc.uuid || '',
                                      otherDoc.sourceUuid || ''
                                    )
                                  }
                                  sx={{
                                    height: '16px',
                                    width: '16px',
                                    '&:hover': { cursor: 'pointer' },
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      </React.Fragment>
                    )
                  }

                  return undefined
                })}
              </Table>
            </Box>
          </Grid>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            pt="32px"
            onClick={() => setCollapseOtherDocs(!collapseOtherDocs)}
          >
            <TrackedLink sx={{ color: '#136D42!important' }}>
              - Collapse Other Documents
            </TrackedLink>
          </Box>
        </TrackedAccordion>
      )
    }
    return undefined
  }

  return isTablet ? (
    <SectionLayout name="documents-section" columns={12} id={id}>
      <Grid item xs={12}>
        <Box display="flex" flexDirection="column" gap="32px">
          <Typography variant="h3">Documents</Typography>
          <TrackedAccordion
            sx={{ paddingLeft: '16px', paddingRight: '16px' }}
            summary="Investment"
            extended={!collapseInvestment}
            summaryOnClick={() => setCollapseInvestment(!collapseInvestment)}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <DocumentCard
                  documentImage={PrivatePlacementMemorandumImage}
                  documentTitle="Flock Homes - Private Placement Memorandum (2025)"
                  documentLink={PrivatePlacementMemorandum2025Link}
                />
              </Grid>
              <Grid item xs={12}>
                <DocumentCard
                  documentImage={LimitedPartnershipAgreementImage}
                  documentTitle="Flock Homes - Amended and Restated Agreement of Limited Partnership (2025)"
                  documentLink={LimitedPartnershipAgreement2025Link}
                />
              </Grid>
              <Grid item xs={12}>
                <DocumentCard
                  documentImage={RedlinedLPAImage03}
                  documentTitle="(Cumulative Redline vs. 03-11-24 Version) - Flock Homes - Amended and Restated Agreement of Limited Partnership (Execution Version)"
                  documentLink={CumRedline31124Link}
                />
              </Grid>
              <Grid item xs={12}>
                <DocumentCard
                  documentImage={LimitedPartnershipAgreementImage}
                  documentTitle="Amended and Restated Agreement of Limited Partnership (2024)"
                  documentLink={LimitedPartnershipAgreement2024Link}
                />
              </Grid>
              <Grid item xs={12}>
                <DocumentCard
                  documentImage={RedlinedLPAImage101523}
                  documentTitle="(Cumulative Redline vs. 10-15-23 Version) - Flock Homes - Amended and Restated Agreement of Limited Partnership (Execution Version)"
                  documentLink={CumRedline101523Link}
                />
              </Grid>
              {documents.length > 0 &&
                renderInvestmentDocumentsForInvestorAndLegalEntities()}
            </Grid>
          </TrackedAccordion>
          <TrackedAccordion
            sx={{ paddingLeft: '16px', paddingRight: '16px' }}
            summary="Fund"
            extended={!collapseFund}
            summaryOnClick={() => setCollapseFund(!collapseFund)}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <DocumentCard
                  documentImage={FactSheetQ32024}
                  documentTitle="Q3 2024 Fact Sheet"
                  documentLink={FactSheetQ32024Link}
                />
              </Grid>
            </Grid>
          </TrackedAccordion>
          {documents.length > 0 && renderTaxDocumentsForLegalEntities()}
          {documents.length > 0 && renderOtherDocuments()}
        </Box>
      </Grid>
    </SectionLayout>
  ) : (
    <SectionLayout name="documents-section" columns={12} id={id}>
      <Grid item xs={12}>
        <Box display="flex" flexDirection="column" gap="32px">
          <Typography variant="h3">Documents.</Typography>
          <TrackedAccordion
            summary="Investment"
            extended={!collapseInvestment}
            summaryOnClick={() => setCollapseInvestment(!collapseInvestment)}
          >
            <Grid container spacing={8}>
              <Grid item xs={4}>
                <DocumentCard
                  documentImage={PrivatePlacementMemorandumImage}
                  documentTitle="Flock Homes - Private Placement Memorandum (2025)"
                  documentLink={PrivatePlacementMemorandum2025Link}
                />
              </Grid>
              <Grid item xs={4}>
                <DocumentCard
                  documentImage={LimitedPartnershipAgreementImage}
                  documentTitle="Flock Homes - Amended and Restated Agreement of Limited Partnership (2025)"
                  documentLink={LimitedPartnershipAgreement2025Link}
                />
              </Grid>
              <Grid item xs={4}>
                <DocumentCard
                  documentImage={RedlinedLPAImage03}
                  documentTitle="(Cumulative Redline vs. 03-11-24 Version) - Flock Homes - Amended and Restated Agreement of Limited Partnership (Execution Version)"
                  documentLink={CumRedline31124Link}
                />
              </Grid>
              <Grid item xs={4}>
                <DocumentCard
                  documentImage={LimitedPartnershipAgreementImage}
                  documentTitle="Amended and Restated Agreement of Limited Partnership (2024)"
                  documentLink={LimitedPartnershipAgreement2024Link}
                />
              </Grid>
              <Grid item xs={4}>
                <DocumentCard
                  documentImage={RedlinedLPAImage101523}
                  documentTitle="(Cumulative Redline vs. 10-15-23 Version) - Flock Homes - Amended and Restated Agreement of Limited Partnership (Execution Version)"
                  documentLink={CumRedline101523Link}
                />
              </Grid>
              {documents.length > 0 &&
                renderInvestmentDocumentsForInvestorAndLegalEntities()}
            </Grid>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              pt="32px"
              onClick={() => setCollapseInvestment(!collapseInvestment)}
            >
              <TrackedLink sx={{ color: '#136D42!important' }}>
                - Collapse Investment Documents
              </TrackedLink>
            </Box>
          </TrackedAccordion>
          <TrackedAccordion
            summary="Fund"
            extended={!collapseFund}
            summaryOnClick={() => setCollapseFund(!collapseFund)}
          >
            <Grid container spacing={8}>
              <Grid item xs={4}>
                <DocumentCard
                  documentImage={FactSheetQ32024}
                  documentTitle="Q3 2024 Fact Sheet"
                  documentLink={FactSheetQ32024Link}
                />
              </Grid>
              <Grid item xs={4}>
                <DocumentCard
                  documentImage={FactSheetQ22024}
                  documentTitle="Q2 2024 Fact Sheet"
                  documentLink={FactSheetQ22024Link}
                />
              </Grid>
              <Grid item xs={4}>
                <DocumentCard
                  documentImage={FactSheetQ12024}
                  documentTitle="Q1 2024 Fact Sheet"
                  documentLink={FactSheetQ12024Link}
                />
              </Grid>
              <Grid item xs={4}>
                <DocumentCard
                  documentImage={FactSheetQ42023}
                  documentTitle="Q4 2023 Fact Sheet"
                  documentLink={FactSheetQ42023Link}
                />
              </Grid>
              <Grid item xs={4}>
                <DocumentCard
                  documentImage={FactSheetQ32023}
                  documentTitle="Q3 2023 Fact Sheet"
                  documentLink={FactSheetQ32023Link}
                />
              </Grid>
              <Grid item xs={4}>
                <DocumentCard
                  documentImage={FactSheetQ22023}
                  documentTitle="Q2 2023 Fact Sheet"
                  documentLink={FactSheetQ22023Link}
                />
              </Grid>
              <Grid item xs={4}>
                <DocumentCard
                  documentImage={FactSheetQ12023}
                  documentTitle="Q1 2023 Fact Sheet"
                  documentLink={FactSheetQ12023Link}
                />
              </Grid>
            </Grid>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              pt="32px"
              onClick={() => setCollapseFund(!collapseFund)}
            >
              <TrackedLink
                sx={{
                  color: '#136D42!important',
                }}
              >
                - Collapse Fund Documents
              </TrackedLink>
            </Box>
          </TrackedAccordion>
          {documents.length > 0 && renderTaxDocumentsForLegalEntities()}
          {documents.length > 0 && renderOtherDocuments()}
        </Box>
      </Grid>
    </SectionLayout>
  )
}

export default DocumentsSection
